import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

export const getNoteConfigurationList = () => {
  return $http.get(
    `/app/info/evaluationconfiguration/back/getNoteEvalConfiguration`,
    {}
  );
};

//修改
export const updateEvalContent = (data) => {
  return $http.post(
    `/app/info/evaluationconfiguration/updateEvalContent`,
    data
  );
};
export const getNote7ConfigurationList = () => {
  return $http.get(
    `/app/info/evaluationconfiguration/back/getNote7EvalConfiguration`,
    {}
  );
};
export const getNote8ConfigurationList = () => {
  return $http.get(
    `/app/info/evaluationconfiguration/back/getNote8EvalConfiguration`,
    {}
  );
};
export const getvipblurb = () => {
  return $http.get(`/app/info/evaluationconfiguration/back/getvipblurb`, {});
};

export const getRights = () => {
  return $http.get(`/app/info/evaluationconfiguration/back/getRights`, {});
};
